@import "src/scss/module";

.Hero {
  border: none;
  border-radius: 0;
  background-color: transparent;
  position: relative;
  // z-index: -1;

  .bg_1 {
    position: absolute;
    right: 0;
    top: 0;
  }

  video {
    object-fit: cover;
  }

  :global {
    .card-img {
      position: absolute;
      z-index: 1;
      border-radius: 0;
      // min-height: 30%;
      min-height: 500px;

      @include media-breakpoint-up(md) {
        min-height: 50vh;
        max-height: 50vh;
      }

      // &:after {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   pointer-events: none;
      //   top: 0;
      //   right: 0;
      //   bottom: -1px;
      //   left: 0;
      //   background-image: url("/assets/hero_foreground.webp");
      //   background-size: cover;
      // }
      &:after {
        // background-image: url("/assets/hero_foreground.webp");
        content: "";
        display: block;
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 0;
        bottom: -1px;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.2) 0%,
          /* Fully transparent at the top */ rgba(255, 255, 255, 0.5) 50%,
          /* 50% transparency at the middle */ rgba(255, 255, 255, 1) 100%
            /* 75% white at the bottom */
        );
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-img-overlay {
      position: relative;
      border-radius: 0;
      text-wrap: balance;
      z-index: 99;
      min-height: 60vh;

      @include media-breakpoint-down(sm) {
        min-height: 100vh;
      }
    }
  }
}
